import React from 'react';
import { graphql } from 'gatsby';

import Page from '../components/Page';

const page = ({ data }) => {
  // const { sanityHome } = data;

  return <Page {...data.sanityPage} />;
};

export const PageQuery = graphql`
  query pageQuery($id: String!) {
    sanityPage(id: { eq: $id }) {
      title
      _rawContent(resolveReferences: { maxDepth: 15 })
      seoSettings {
        metaDescription
        title
      }
    }
  }
`;

export default page;
