import React from 'react';
import styled from 'styled-components';

import Content from './Content';

const Page = ({ _rawContent }) => {
  return <StyledPage>{_rawContent && <Content>{_rawContent}</Content>}</StyledPage>;
};

const StyledPage = styled.div``;

export default Page;
